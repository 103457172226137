<template>
    <div class="password-reset-container">
      <div class="password-reset-box">
        <div class="logo-container">
          <img src="images/logo-domos.png" alt="Company Logo" class="company-logo">
        </div>
        <h1 class="reset-title">Reset Your Password</h1>
        <div v-if="verified" class="reset-form">
          <input type="password" v-model="newPassword" placeholder="New Password" class="reset-input">
          <input type="password" v-model="confirmPassword" placeholder="Confirm New Password" class="reset-input">
          <a-button @click="resetPassword" type="primary">Reset Password</a-button>
        </div>
        <div v-else class="verifying-request">
          <p>Verifying your request...</p>
        </div>
        <a href="mailto:support@joindomos.com?subject=Sign In Support" target="_blank" class="help-link">Need help? Email support@joindomos.com</a>
      </div>
    </div>
  </template>

  <script>
import { auth } from '../firebaseConfig';
  import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
  
  export default {
    name: 'PasswordReset',
    data() {
      return {
      verified: false,
      newPassword: '',
      confirmPassword: '',
      actionCode: '',
      email: null
    };
    },
    methods: {
      verifyActionCode(actionCode) {
        console.log('Verifying action code:', actionCode);
        verifyPasswordResetCode(auth, actionCode)
          .then((email) => {
            console.log(`Code is verified for email: ${email}`);
            this.verified = true;
            this.email = email; // If you want to display the email to the user
          })
          .catch((error) => {
            console.error('Error verifying action code:', error);
            alert('Invalid or expired password reset code. Please try to reset again or reach out to support.');
            this.$router.push('/sign-in');
          });
      },

      resetPassword() {
      if (!this.verified) {
        alert('Please verify the reset code before resetting the password.');
        return;
      }

      if (this.newPassword !== this.confirmPassword) {
        alert('The passwords do not match. Please try again.');
        return;
      }

      console.log('Resetting password for action code:', this.actionCode);
      confirmPasswordReset(auth, this.actionCode, this.newPassword)
        .then(() => {
            console.log('Password reset successful');
            alert('Password reset successful. You can now login with your new password.');
            this.$router.push('/sign-in');
        })
        .catch((error) => {
            console.error('Error resetting password:', error);
            alert('Failed to reset password. Please try again.');
        });
    }
    },
 mounted() {
      console.log('PasswordReset component mounted.');
      const queryParams = new URLSearchParams(window.location.search);
      this.actionCode = queryParams.get('oobCode');
      console.log('Received action code:', this.actionCode);
  
      if (this.actionCode) {
        this.verifyActionCode(this.actionCode);
      } else {
        console.error('No action code found in URL query parameters.');
        alert('No password reset code found. Please try to reset the password again or reach out to support.');
        this.$router.push('/'); // Redirect to home page or any other appropriate page
      }
    } 
  };
  </script>
  
  <style scoped>
.password-reset-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}

.password-reset-box {
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 2em;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.logo-container {
  margin-bottom: 1em;
}

.company-logo {
  width: 100px;
  height: auto;
}

.reset-title {
  color: #333;
  margin-bottom: 2em;
}

.reset-form {
  display: flex;
  flex-direction: column;
}

.reset-input {
  padding: 1em;
  margin-bottom: 1em;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.reset-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 1em;
  border-radius: 4px;
  cursor: pointer;
}

.help-link {
  display: block;
  margin-top: 1em;
  color: #aaa;
  text-decoration: none;
}

.verifying-request {
  color: #333;
}
</style>